/* You can add global styles to this file, and also import other style files */
@import "./theme.scss";

:root {
  --dark-blue: #00364d;
  --light-blue: #0075a3;
  --purple: #9b57a2;
  --green: #48831b;
  --red: #e12a19;
  --grey: #5e5f61;
}

.dark-blue {
  color: #00364d
}

.light-blue {
  color: #0075a3
}

.purple {
  color: #9b57a2
}

.green {
  color: #48831b
}

.red {
  color: #e12a19
}

.grey {
  color: #5e5f61
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}

.container {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

h1,
h2,
h3,
h4 {
  color: var(--grey);
}

.top-margin-30 {
  margin-top: 30px;
}

.mat-dialog-container {
  max-width: 98vw !important;
  max-height: 95vh !important;
}

// .about-header{
//   max-width: 98vw !important;
//   max-height:15vh !important;
// }

.mat-dialog-content {
  max-width: 98vw !important;
  max-height: 95vh !important;
}

.mySedgwick-popupFooter {
  max-width: 98vw !important;
  max-height: 15vh !important;
}

.mat-icon.mat-primary {
  color: var(--dark-blue);
}

input[type='submit'],
input[Type='button'] {
  background-color: var(--dark-blue) !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: var(--dark-blue) !important;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled {
  background-color: var(--grey) !important;
}

.mat-dialog-content.mySedwick-popupContent {
  max-width: 500px !important;
  padding: 25px !important;
}

.mat-radio-inner-circle {
  background-color: #0075a3 !important;
}

.mat-dialog-actions.mySedwick-popupFooter {
  padding-top: 30px !important;
  padding-bottom: 50px !important;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font-size: 20px;
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}


@media screen and (max-width:492px) {
  .cdk-overlay-pane.mat-datepicker-popup {
    // right: auto !important;
    left: 51px;
  }
  table {
    table-layout: fixed !important;
    width: 100% !important;
    height: 100% !important;
  }
  .mat-datepicker-content {
    table-layout: auto !important;
    width: 100% !important;
    height: 100% !important;
  }

  .mat-calendar {
    width: 100% !important;
    height: 100% !important;
  }

  .registration-container,
  .forgotpassword-container,
  .forgotUserName-container {
    margin: 30px 0px !important;
    width: 100% !important;
  }
}

@media screen and (min-width:500px) and (max-width:520px) {
  .cdk-overlay-pane.mat-datepicker-popup {
    right: auto !important;
    left: 21px;
  }
  table {
    table-layout: fixed !important;
    width: 100% !important;
    height: 100% !important;
  }
  .mat-datepicker-content {
    table-layout: auto !important;
    width: 100% !important;
    height: 100% !important;
  }

  .mat-calendar {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (min-width:630px) and (max-width:650px) {

  table {
    table-layout: fixed !important;
    width: 100% !important;
    height: 100% !important;
  }
  .mat-datepicker-content {
    table-layout: auto !important;
    //width: 100% !important;
    height: 100% !important;
  }

  .mat-calendar {
    //width: 100% !important;
    height: 100% !important;
  }
}
@media screen and (min-width:331px) and (max-width:351px) {
  .cdk-overlay-pane.mat-datepicker-popup {
    right: auto !important;
    left: 21px;
  }
  table {
    table-layout: fixed !important;
    width: 100% !important;
    height: 100% !important;
  }
  .mat-datepicker-content {
    table-layout: auto !important;
    width: 100% !important;
    height: 100% !important;
  }

  .mat-calendar {
    width: 100% !important;
    height: 100% !important;
  }
  .mat-select-panel {
    min-width: calc(100% + 5px) !important;
  }
}

@media screen and (min-width:319px) and (max-width:330px) {
   .BDC_CaptchaDiv  {
    width:250px !important;
    display: inline-block;
  }
  .BDC_CaptchaImageDivSm  {
    width:210px !important;
  }
  .BDC_CaptchaImage  {
    width:210px !important;
  }
  #MeltingHeat_CaptchaIconsDiv {
    width:20px !important;
    margin-left: -13% !important;
  }
}
input[type='submit']:focus,
input[type='button']:focus {
  border: 2px solid white !important;
  outline: 3px solid black !important;
}

button:focus {
  border: 2px solid white !important;
  outline: 3px solid black !important;
}

a:focus,
div:focus,
p:focus,
span:focus,
label:focus,
mat-label:focus {

  outline: 3px solid black !important;
}

a {
  color: var(--light-blue) !important;
}

.footerLink {
  color: #0075a3;
  text-decoration: none;
  margin: 0px 1px 0px 1px;
  padding-bottom: 10px;
  padding-left: 0px;
}

.roboto20 {
  font-size: 20px;
}

.roboto16 {
  font-size: 16px;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field-invalid.ng-touched .ng-star-inserted {
  color: var(--red) !important;
}

.error-message {
  color: var(--red);
  font-weight: bold;
  font-family: Roboto;
  font-size: 14px;
  margin-top: 1%;
  text-align: right;
  z-index: 3;
}

.errormessagemargintop-20 {
  margin-top: -20px !important;

}

.mat-focused {
  border: 2px solid black !important;
  z-index: 2;
}

.cdk-keyboard-focused {
  border: 2px solid black !important;
}

.mat-ripple-element {
  display: none !important;
}


/* Live Chat*/
.repostionChat {
  left: 701.5px;
  top: 507px;
  width: 250px;
}

/* Toast : alert classes*/
.alert-danger {
  background-color: var(--purple) !important
}

.alert-success {
  background-color: var(--green) !important
}

.alert-info {
  background-color: var(--dark-blue) !important
}

.alert-userError {
  background-color: var(--red) !important
}

.alert-blank {
  color: black !important
}

.alert {
  padding: 20px;
  color: white;
  margin-bottom: 15px;
  text-align: left;
  border-radius: 5px;
}

.applicationcontainer {
  height: 100%;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

.spanLink {
  color: white !important;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.spanLink:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}

.top10 {
  margin-top: 10px;
}

.hideBackground .mat-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.0);
}

.mat-select-panel .mat-option {
  white-space: normal;
  max-width: -webkit-fill-available;
  text-decoration: none;
  line-height: inherit !important;
}

.mat-select-panel .mat-active {
  border: 2px solid black !important;
}

.mat-checkbox:focus {
  outline: 2px solid black !important;
  //outline: 3px solid black !important;
}

#bottomMenu ul {
  list-style: none;
  padding-left: -40px !important;
}

#bottomMenu li {
  display: inline;
}

.mat-calendar-table-header th{
  color:#000000;
}

.BDC_ReloadIcon,.BDC_SoundIcon{
  filter: contrast(2);
}


